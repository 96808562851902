import * as React from "react";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {importConcepts, searchConceptByPURL} from '../network'
import {ConceptTypeButtons, linkFormatter, paragraphFormatter} from "../components";

import './ConceptManager.css'
import {AppState, ConceptManagerActions, ConceptManagerState} from "../AppDB";
import {connect} from "react-redux";
import {generatePURLFromPreferredName} from "../purl";
import {useState} from "react";

type T = any;

const columns = [{
  dataField: 'names',
  formatter: paragraphFormatter,
  text: 'Preferred Name',
  sort: true
}, {
  dataField: 'synonyms',
  text: 'Synonyms',
  formatter: paragraphFormatter,
  sort: true
}, {
  dataField: 'definitions',
  text: 'Definition',
  formatter: paragraphFormatter,
  sort: false
}, {
  dataField: 'cuis',
  text: 'CUI',
  formatter: paragraphFormatter,
  sort: true
}, {
  dataField: 'ontologies',
  text: 'Ontologies',
  formatter: paragraphFormatter,
  sort: true
}, {
  dataField: 'purl',
  text: 'PURL',
  sort: true,
  formatter: linkFormatter
}];

const _ImportConceptsModal: React.FC<ConceptManagerState & typeof ConceptManagerActions> =
  ({
     newConceptToImport,
     conceptsToImport,
     displayImportConceptsModal,
     setDisplayImportConceptsModal,
     updateNewConceptToImport
   }) => {

    const toggleModal = () => {
      setDisplayImportConceptsModal(!displayImportConceptsModal);
    };

    const [saving, setSaving] = useState<boolean>(false);

    const [invalidPrefNameMsg, setInvalidPrefNameMsg] = useState<null | string>("Preferred name is required");
    const validatePreferredName = (s: string) => {
      if (!s || s.length === 0)
        setInvalidPrefNameMsg("Preferred name is required");
      else if (s.match(/[^a-zA-Z0-9\-\s]/))
        setInvalidPrefNameMsg("Only A-Z, a-z, 0-9, -, and spaces allowed");
      else {
        setInvalidPrefNameMsg(null)
      }
    };

    const validatePURL = (s: string) => {
      const purl = generatePURLFromPreferredName(s);
      searchConceptByPURL(purl, (result) => {
        if (result) {
          console.debug(`Found existing PURL:`);
          console.debug(result);
          setInvalidPrefNameMsg("PURL already taken, please change the preferred name");
        } else {
          console.debug(`Existing concept not found with PURL: ${purl}`);
          setInvalidPrefNameMsg(null);
        }
      })
    };

    const clearNewConceptToImport = () => {
      updateNewConceptToImport('preferred-name', '');
      validatePreferredName('');
      updateNewConceptToImport('description', '');
      validateDescription('');
      updateNewConceptToImport('type', null);
    };

    const [invalidDescMsg, setInvalidDescMsg] = useState<null | string>("Description is required");
    const validateDescription = (s: string) => {
      if (!s || s.length === 0)
        setInvalidDescMsg("Description is required");
      else
        setInvalidDescMsg(null);
    };

    return (
      <Modal className="w-100 modal-dialog modal-xl"
             centered={true}
             isOpen={displayImportConceptsModal} toggle={toggleModal}>
        <ModalHeader>Import Concepts</ModalHeader>
        <ModalBody className="w-100">
          <p>This will import the following concepts and link them all with bidirectional <strong><em>IS
            A</em></strong> relationships</p>

          <BootstrapTable bootstrap4
                          columns={columns}
                          keyField={'purl'}
                          defaultSorted={[{
                            dataField: 'names',
                            order: 'asc'
                          }]}
                          data={Object.values(conceptsToImport)}
          />

          <h5>Please set the following fields prior to import:</h5>
          <FormGroup>
            <Label>Preferred name (to be displayed to all users, patients and clinicians)</Label>
            <Input className={invalidPrefNameMsg ? "is-invalid" : ""}
                   placeholder="Preferred/display name"
                   value={newConceptToImport["preferred-name"]}
                   onChange={(event) => {
                     const s = event.target.value;
                     updateNewConceptToImport('preferred-name', s);
                     validatePreferredName(s)
                     // console.debug(`Updating concept preferred name: ${event.target.value}`);
                   }}
                   onBlur={() => {
                     validatePURL(newConceptToImport['preferred-name']);
                   }}
                   required/>
            <div className="invalid-feedback">{invalidPrefNameMsg}</div>
            <em className="grey">Generated PURL: {generatePURLFromPreferredName(newConceptToImport["preferred-name"])}</em>
          </FormGroup>

          <FormGroup>
            <Label>Description:</Label>
            <br/>
            <Input className={invalidDescMsg ? "is-invalid" : ""}
                   placeholder="Description of the concept, how it will be used, context, etc."
                   type="textarea"
                   rows={5}
                   value={newConceptToImport.description}
                   onChange={(event) => {
                     const s = event.target.value;
                     updateNewConceptToImport('description', s);
                     validateDescription(s);
                   }}
            />
            <div className="invalid-feedback">{invalidDescMsg}</div>
          </FormGroup>

          <FormGroup>
            <Label>Concept Type:</Label>
            <br/>
            <ConceptTypeButtons selected={newConceptToImport.type}
                                onSelect={(type) => {
                                  updateNewConceptToImport('type', type);
                                }}
            />
          </FormGroup>

        </ModalBody>
        <ModalFooter className="justify-content-end">
          <div className="flex-row">
            <Button className="mr-2"
                    color="info"
                    disabled={saving}
                    onClick={() => {
                      setSaving(true);
                      console.debug(`Importing concepts...`);
                      console.debug(`with preferred name: ${newConceptToImport["preferred-name"]}`);
                      console.debug(`with type: ${newConceptToImport.type}`);
                      console.debug(conceptsToImport);
                      importConcepts(
                        newConceptToImport,
                        Object.values(conceptsToImport),
                        (results) => {
                          console.debug(`Import results:`);
                          console.debug(results);

                          clearNewConceptToImport();
                          setSaving(false);
                          setDisplayImportConceptsModal(false);
                        }
                      )
                    }}>
              {saving ? "Saving..." : "Save"}
            </Button>
            <Button color="danger"
                    onClick={() => setDisplayImportConceptsModal(false)}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  };
export const ImportConceptsModal = connect(
  (state: AppState) => state.conceptManager,
  ConceptManagerActions
)(_ImportConceptsModal);