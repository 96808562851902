import * as React from 'react'
import {FormGroup, Input} from "reactstrap";
import {AppDB, AppDBActions, useGlobal} from "../AppDB";
import {RuleField} from "../qrm"
import v4 from "uuid/v4";
import {Typeahead} from "react-bootstrap-typeahead";
import {useState} from "react";
import {getTags} from "../network";
import {useEffect} from "react";

export const NotesCard: React.FC<{}> = () => {
  const [{activeRule}, {updateRuleByField}] = useGlobal<AppDB, AppDBActions>(
    (db: AppDB) => db,
    (actions: AppDBActions) => actions
  );

  const notesText = activeRule.notes;

  const [allTags, setTags] = useState<string[]>([]);

  const getAllTags = () => {
    setTags([]);
    console.debug(`Getting all tags`);
    getTags((tags) => {
      console.debug(`Tags: ${JSON.stringify(tags)}`);
      setTags(tags.sort((a,b) => {return a.localeCompare(b)}))
    });
  };

  useEffect(() => {
    console.debug('Calling NotesCard effect...');
    getAllTags();
  }, []);

  return (
    <div>
      <FormGroup>
        <Input type="textarea" rows={10} value={notesText}
               onChange={(e) => {
                 updateRuleByField(RuleField.NOTES, e.target.value);
               }}/>
      </FormGroup>
      <div className="d-flex flex-row">
        <p className="my-auto mr-2">Tags:</p>
        <div className="w-100">
          <Typeahead id={v4()}
                     allowNew={true}
                     multiple={true}
                     options={allTags}
                     onChange={(selected) => {
                       // sorta hacky...??? the Typeahead expected and uses strings but when
                       // creating a new item, an object is created with a field "label"
                       // so we loop through and extract the label if it's an object
                       updateRuleByField(RuleField.TAGS, selected.map((s) => (s as any).label || s));
                     }}
                     selected={activeRule.tags}
                     defaultSelected={[]}/>
        </div>
      </div>
    </div>
  )
};