import {Concept} from "./qrm";

export default class ConceptMap {
  concepts: Concept[];

  constructor(concepts: Concept[]) {
    this.concepts = concepts.sort(
      (a, b) => a["preferred-name"].localeCompare(b["preferred-name"])
    );
  }

  getByPURL(purl: string): Concept | undefined {
    return this.concepts.find((concept) => {
      return concept.purl === purl
    });
  }

  // asList() {
  //   return this.concepts
  // }
  asList() {
    return this.concepts
  }
}
