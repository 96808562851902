import * as React from 'react'
import {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {SelectableButton, TabButton} from '../components';
import {FactCard} from "./FactCard";
import {ActionCard} from "./ActionCard";
import {NotesCard} from "./NotesCard";
import {DEBUG} from "../debug";

import './GuidedRuleEditor.css'
import {AppDB, AppDBActions, blankRule, RuleScreens, useGlobal} from "../AppDB";
import {
  BooleanGroup, BooleanGroupTypes, RuleField
} from "../qrm"
import {saveRule} from "../network";
import {Form, FormGroup, Input, Label} from "reactstrap";

// export class FactStore extends React.Component {
//   constructor() {
//     super({})
//   }
//
//   facts: FactProp[] = [];
//
//   add(f: FactProp) {
//     this.facts.push(f)
//   }
//
//   newFact() {
//     this.facts.push({concept_purl: "d"});
//     console.debug("Adding new fact, totaling " + this.facts.length + " facts");
//     return this
//   }
// }

const BooleanCard: React.FC<{
  path: number[]
  criteria: BooleanGroup
}> = (props) => {
  const [, {newFact, newGroup, updateGroup, deleteGroup}] = useGlobal<AppDB, AppDBActions>(
    (db: AppDB) => db,
    (actions: AppDBActions) => actions
  );

  const booleanType = props.criteria.booleanType;
  const subgroups = props.criteria.subgroups;
  const facts = props.criteria.facts;

  var booleanText;
  switch (booleanType) {
    case BooleanGroupTypes.AND:
      booleanText = "All criteria must be met";
      break;
    case BooleanGroupTypes.OR:
      booleanText = "At least one criteria must be met";
      break;
    default:
      booleanText = "SOMETHING IS VERY WRONG"
  }

  return (
    <div className="card container mt-3 mb-2">
      <div className="d-flex flex-row pt-3">
        <div className="my-auto">
          <h5 className="mb-0">
            <div className="btn-group my-auto">
              <SelectableButton label="AND"
                                value={booleanType}
                                selectedButtonClass="btn-primary"
                                defaultButtonClass="btn-outline-primary"
                                onClick={() => {
                                  updateGroup(props.path, "booleanType", BooleanGroupTypes.AND)
                                }}/>
              <SelectableButton label="OR"
                                value={booleanType}
                                selectedButtonClass="btn-primary"
                                defaultButtonClass="btn-outline-primary"
                                onClick={() => {
                                  updateGroup(props.path, "booleanType", BooleanGroupTypes.OR)
                                }}/>
            </div>
            <small className="ml-3 text-muted">{booleanText}</small>
          </h5>
          <small className="text-muted">There are {subgroups.length + facts.length} criteria in this group</small>
        </div>

        <div className="ml-auto">
          {
            props.path.length > 0 ?
              <button className="ml-1 btn btn-outline-danger btn-no-border"
                      onClick={() => deleteGroup(props.path)}>
                <FontAwesomeIcon icon={faTrashAlt}/>
              </button>
              :
              <div/>
          }
        </div>

      </div>
      <small className="text-muted">{DEBUG ? props.criteria.id : ""}</small>

      <div>
        {
          subgroups.map((group, index) => {
            return (
              <BooleanCard path={props.path.concat(index)} criteria={group} key={group.id}/>
            )
          })
        }
      </div>
      <div>
        {
          facts.map((fact, index) => {
            return (
              <FactCard path={props.path.concat(index)} fact={fact} key={fact.id}/>
            )
          })
        }
      </div>
      <div className="ml-auto mb-3">
        <button onClick={() => {
          newGroup(props.path)
        }}
                className="btn btn-sm btn-primary">Add Boolean Group
        </button>
        <button onClick={() => {
          newFact(props.path)
        }}
                className="ml-2 btn btn-sm btn-secondary">Add Fact
        </button>
      </div>
    </div>
  )
};

enum Tab {
  Facts = "Facts",
  Actions = "Actions",
  Notes = "Notes"
}

export const GuidedRuleEditor: React.FC = () => {
  const [{activeRule}, {setActiveRuleManagerScreen, setActiveRule, newAction, updateRuleByField, addAlert}] = useGlobal<AppDB, AppDBActions>(
    (db: AppDB) => db,
    (actions: AppDBActions) => actions
  );
  const [editingRuleName, setEditRuleName] = useState(activeRule.name.length === 0);
  const [tempRuleName, setTempRuleName] = useState(activeRule.name);

  const goBackToRuleManager = () => {
    setActiveRuleManagerScreen(RuleScreens.RuleManager);
    setActiveRule(blankRule(""));
  };

  const [activeTab, setActiveTab] = useState<Tab>(Tab.Facts);

  var activeTabDiv;
  switch (activeTab) {
    case Tab.Facts:
      activeTabDiv =
        <BooleanCard path={[]} criteria={activeRule.criteria}/>;
      break;
    case Tab.Actions:
      activeTabDiv =
        <ActionCard activeRule={activeRule} newAction={newAction}/>;
      break;
    case Tab.Notes:
      activeTabDiv =
        <NotesCard/>;
      break;
    default:
      activeTabDiv =
        <div>
          SOMETHING IS WRONG
        </div>
  }


  return (
    <div className="GuidedRuleEditor container mb-4">
      <div className="mb-3 row w-100">
        {
          editingRuleName ?
            <div className="col-md-6">
              <input autoFocus={true}
                     className="form-control" type="text" value={tempRuleName}
                     placeholder="Please set a name for this rule"
                     onChange={(event) => setTempRuleName(event.target.value)}
                     onBlur={() => {
                       const s = tempRuleName.trim();
                       if (s.length !== 0) {
                         updateRuleByField(RuleField.NAME, s);
                         setEditRuleName(!editingRuleName);
                       }
                     }}
              />
            </div>
            :
            <div className="col-lg-8 d-flex flex-row">
              <h3>{activeRule.name}</h3>
              <button className="btn btn-link"
                      onClick={() => {
                        setEditRuleName(!editingRuleName);
                      }}>
                Edit
              </button>
            </div>
        }
        <div className="col-lg-4 d-flex p-0 flex-row justify-content-end">
          <button className="btn btn-success mr-2"
                  onClick={() => {
                    console.debug('Saving rule...');
                    saveRule(activeRule, () => {
                      console.debug('Alert added');
                      addAlert({
                        message: "Rule saved!",
                        color: "success",
                        timeout: 3000 //ms
                      })
                    });
                  }}>
            Save
          </button>
          <button className="btn btn-info mr-2"
                  onClick={() => {
                    saveRule(activeRule, () => {

                      goBackToRuleManager();

                      addAlert({
                        message: "Rule saved!",
                        color: "success",
                        timeout: 3000 //ms
                      });
                    });
                  }}>
            Save & Close
          </button>
          <button className="btn btn-outline-danger"
                  onClick={() => {
                    goBackToRuleManager()
                  }}>
            Cancel
          </button>
        </div>
      </div>
      {
        DEBUG ? <small className="text-muted">{activeRule.id}</small> : ""
      }

      <div className="mt-3 row w-100">
        <div className="col-sm-12">
          <Form>
            <FormGroup className="form-check">
              <Input type={"checkbox"}
                     onChange={() => {
                       updateRuleByField(RuleField.DISABLED, !activeRule.disabled);
                     }}
                     checked={activeRule.disabled}
              />
              <Label className="form-check-label">Disable rule</Label>
            </FormGroup>
          </Form>
        </div>
      </div>

      <nav className="mt-3 w-100">
        <div className="nav nav-tabs" id='nav-tab' role='tablist'>
          <TabButton tab={Tab.Facts} activeTab={activeTab} setActiveTab={setActiveTab}/>
          <TabButton tab={Tab.Actions} activeTab={activeTab} setActiveTab={setActiveTab}/>
          <TabButton tab={Tab.Notes} activeTab={activeTab} setActiveTab={setActiveTab}/>

        </div>
      </nav>
      <div className="tab-content w-100" id="nav-tabContent">
        <div className=" p-2 tab-pane fade show active" id="nav-facts" aria-labelledby="nav-facts-tab">
          {activeTabDiv}
        </div>
      </div>


      {/*<button className="btn p-0"*/}
      {/*        onClick={() => {*/}
      {/*          setShowFacts(!showFacts);*/}
      {/*        }}>*/}
      {/*  <h3 className="text-left">Facts</h3>*/}
      {/*  <small className="text-muted">Click to toggle display of facts</small>*/}
      {/*</button>*/}


      {/*<hr className="w-100"/>*/}
      {/*<button className="btn p-0"*/}
      {/*        onClick={() => {*/}
      {/*          setShowActions(!showActions);*/}
      {/*        }}>*/}
      {/*  <h3 className="text-left">Actions</h3>*/}
      {/*  <small className="text-muted">Click to toggle display of actions</small>*/}
      {/*</button>*/}
      {/*{showActions ?*/}

      {/*  : <div/>*/}
      {/*}*/}
      {/*<div className="w-100">*/}

      {/*</div>*/}
    </div>
  )
};