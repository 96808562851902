import * as React from 'react'

import globalHook, {Store} from 'use-global-hook';

import {combineReducers, configureStore, createSlice} from "@reduxjs/toolkit";

import v4 from "uuid/v4";
import {getRulesByNamespace} from "./network";
import {
  Action, Alert,
  AppSection, BioportalConcept,
  BooleanGroup,
  BooleanGroupTypes,
  ChecklistOption,
  Concept,
  Fact,
  MultiPURLConcept,
  Namespace,
  NewConceptInfo,
  PathElement,
  Rule,
  RuleField,
} from "./qrm";


const setActiveNS = (
  db: Store<AppDB, AppDBActions>,
  ns: Namespace
) => {
  console.debug(`Setting activeNamespace: ${JSON.stringify(ns)}`);
  db.state.activeNamespace = ns;

  db.setState(db.state)
};

const setRulesOfActiveNS = (
  db: Store<AppDB, AppDBActions>,
  rules: Rule[]
) => {
  console.debug(`Setting rules of active namespace: ${JSON.stringify(rules)}`);
  db.state.rulesOfActiveNamespace = rules;

  db.setState(db.state)
};

const setActiveAppFunction = (
  db: Store<AppDB, AppDBActions>,
  appFunction: AppFunction
) => {
  console.debug(`Setting activeAppFunction: ${appFunction}`);
  db.state.activeAppFunction = appFunction;

  db.setState(db.state)
};

const setActiveRuleManagerScreen = (
  db: Store<AppDB, AppDBActions>,
  screen: RuleScreens
) => {
  console.debug(`Setting activeRuleManagerScreen: ${screen}`);
  db.state.activeRuleManagerScreen = screen;

  db.setState(db.state)
};

export enum AppFunction {
  ConceptManager,
  RuleManager
}

export enum ConceptScreens {
  ConceptManager = "ConceptManager"
}

export enum RuleScreens {
  RuleManager = "RuleManager",
  GuidedRuleEditor = "GuidedRuleEditor",
}

export type AppDB = {
  activeAppFunction: AppFunction,

  activeRuleManagerScreen: RuleScreens,

  activeNamespace: Namespace | null,
  rulesOfActiveNamespace: Rule[],

  activeRule: Rule,

  alerts: Alert[]
}

export type AppDBActions = {
  setActiveNS: (ns: Namespace) => void;
  setRulesOfActiveNS: (rules: Rule[]) => void;

  setActiveAppFunction: (appFunction: AppFunction) => void;

  setActiveRuleManagerScreen: (screen: RuleScreens) => void;

  setActiveRule: (rule: Rule) => void;
  updateRuleName: (name: string) => void;
  updateRuleByField: (key: RuleField, value: any) => void;
  updateRules: (namespaceId: string) => void;

  newFact: (path: number[]) => void;
  updateFact: (path: number[], key: string, value: any) => void;
  deleteFact: (path: number[]) => void;
  updateGroup: (path: number[], key: string, value: any) => void;
  deleteGroup: (path: number[]) => void;
  newGroup: (path: number[]) => void;

  newAction: () => void;
  updateAction: (path: PathElement[], keys: string[], value: any) => void;
  deleteWithinAction: (path: PathElement[]) => void;
  addChecklistOption: (path: PathElement[]) => void;

  addAlert: (alert: Alert) => void;
  popAlert: () => void;
}

const addAlert = (
  db: Store<AppDB, AppDBActions>,
  alert: Alert
) => {
  db.state.alerts.push(alert);

  console.debug(`Alerts: ${JSON.stringify(db.state.alerts)}`);

  db.setState(db.state)
};

const popAlert = (
  db: Store<AppDB, AppDBActions>
) => {
  db.state.alerts.pop();
  console.debug(`post-popping Alerts: ${JSON.stringify(db.state.alerts)}`);

  db.setState(db.state)
};

const getFactByPath = (criteria: BooleanGroup, path: number[]): [Fact, BooleanGroup, number] => {
  const subgroupPath = path.slice(0, -1);
  const factIndex = path.slice(-1)[0];

  console.debug(`Updating fact located in subgroups ${JSON.stringify(subgroupPath)}, fact ${factIndex}`);

  var subgroup: BooleanGroup = criteria;
  for (var i of subgroupPath) {
    subgroup = subgroup.subgroups[i];
  }

  return [subgroup.facts[factIndex], subgroup, factIndex];
};

const setActiveRule = (
  db: Store<AppDB, AppDBActions>,
  rule: Rule) => {
  db.state.activeRule = rule;

  db.setState(db.state)
};

const updateRuleByField = (
  db: Store<AppDB, AppDBActions>,
  key: string,
  value: any
) => {
  (db.state.activeRule as any)[key] = value;

  db.setState(db.state)
};

const updateRuleName = (
  db: Store<AppDB, AppDBActions>,
  name: string
) => {
  db.state.activeRule.name = name;

  db.setState(db.state)
};

const updateFact = (
  db: Store<AppDB, AppDBActions>,
  path: number[],
  key: string,
  value: any
) => {
  const criteria = db.state.activeRule.criteria;

  const [fact, ,] = getFactByPath(criteria, path);

  console.debug(`Updating fact ${fact.id}, ${key} -> ${value}`);

  // const fact = db.state.factStore.getById(factId);
  (fact as any)[key] = value || "";
  // console.debug(`Updating fact ${fact.id}, ${key} -> ${value}`);
  db.setState({...{activeRule: {criteria: criteria}}, ...db.state})
};

const addChecklistOption = (
  db: Store<AppDB, AppDBActions>,
  path: PathElement[]
) => {
  const actions = db.state.activeRule.actions;

  // get the action only cause we want to print id
  const action = actions[path[0] as any] as Action;
  console.debug(`Adding new checklist option ${action.id}, ${JSON.stringify(path)}`);

  console.debug(`path: ${JSON.stringify(path)}`);

  const firstPartOfPath = path.slice(0, -1);
  const finalPathElement = path.slice(-1)[0];

  // traverse path
  var objAtEndOfPath = actions as any;
  for (var p of firstPartOfPath) {
    objAtEndOfPath = (objAtEndOfPath as any)[p];
  }

  if (objAtEndOfPath[finalPathElement as any] === undefined) {
    (objAtEndOfPath[finalPathElement as any] as any) = []
  }

  objAtEndOfPath = objAtEndOfPath[finalPathElement as any];

  (objAtEndOfPath as ChecklistOption[]).push({
    text: "",
    value: ""
  });

  db.setState({...{activeRule: {actions: actions}}, ...db.state})
};

const updateAction = (
  db: Store<AppDB, AppDBActions>,
  path: PathElement[],
  keys: string[],
  value: any
) => {
  const actions = db.state.activeRule.actions;

  // get the action only cause we want to print id
  const action = actions[path[0] as any] as Action;
  console.debug(`Updating action ${action.id}, ${JSON.stringify(keys)} -> ${value}`);

  console.debug(`path: ${JSON.stringify(path)}`);
  console.debug(`keys: ${JSON.stringify(keys)}`);

  // traverse path
  var objAtEndOfPath = actions;
  for (var p of path) {
    objAtEndOfPath = (objAtEndOfPath as any)[p];
  }

  // traverse keys
  const keyPath = keys.slice(0, -1);
  const finalKey = keys.slice(-1)[0];

  console.debug(`keyPath: ${JSON.stringify(keyPath)}`);
  console.debug(`finalKey: ${JSON.stringify(finalKey)}`);

  var objAtEndOfKeyPath = objAtEndOfPath;
  for (var i of keyPath) {
    objAtEndOfKeyPath = (objAtEndOfKeyPath as any)[i]
  }

  // final update
  (objAtEndOfKeyPath as any)[finalKey] = value;

  db.setState({...{activeRule: {actions: actions}}, ...db.state})
};

const deleteWithinAction = (
  db: Store<AppDB, AppDBActions>,
  path: PathElement[],
  //keys: string[],
) => {
  const actions = db.state.activeRule.actions;

  // get the action only cause we want to print id
  const action = actions[path[0] as any] as Action;
  console.debug(`Deleting from action ${action.id}, ${JSON.stringify(path)}`);

  console.debug(`path: ${JSON.stringify(path)}`);

  const firstPartOfPath = path.slice(0, -1);
  const finalPathElement = path.slice(-1)[0];
  const finalPathElementIndex = parseInt(finalPathElement as string);

  // traverse path
  var objAtEndOfPath = actions;
  for (var p of firstPartOfPath) {
    objAtEndOfPath = (objAtEndOfPath as any)[p];
  }

  objAtEndOfPath.splice(finalPathElementIndex, 1);

  db.setState({...{activeRule: {actions: actions}}, ...db.state})
};


const deleteFact = (
  db: Store<AppDB, AppDBActions>,
  path: number[]
) => {
  const criteria = db.state.activeRule.criteria;

  const [fact, parentGroup, factIdx] = getFactByPath(criteria, path);

  console.debug(`Deleting fact ${fact.id}`);

  parentGroup.facts.splice(factIdx, 1);

  // console.debug(factStore)
  db.setState({...{activeRule: {criteria: criteria}}, ...db.state})
};

const getGroupByPath = (criteria: BooleanGroup, path: number[]): [BooleanGroup, BooleanGroup | undefined, number | undefined] => {
  const parentPath = path.slice(0, -2);
  const targetIndex = path.slice(-2)[0];

  console.debug(`getGroupByPath, length ${path.length}: ${JSON.stringify(path)}`);

  if (path.length > 0) {

    var subgroup: BooleanGroup = criteria;
    for (var i of parentPath) {
      subgroup = subgroup.subgroups[i];
    }

    return [subgroup.subgroups[targetIndex], subgroup, targetIndex]
  } else {
    return [criteria, undefined, undefined]
  }
};


const updateGroup = (
  db: Store<AppDB, AppDBActions>,
  path: number[],
  key: string,
  value: any
) => {
  const criteria = db.state.activeRule.criteria;

  const [targetGroup, ,] = getGroupByPath(criteria, path);

  console.debug(`Updating group ${targetGroup.id}, ${key} -> ${value}`);

  // const fact = db.state.factStore.getById(factId);
  (targetGroup as any)[key] = value || "";
  // console.debug(`Updating fact ${fact.id}, ${key} -> ${value}`);
  db.setState({...{activeRule: {criteria: criteria}}, ...db.state})
};

const deleteGroup = (
  db: Store<AppDB, AppDBActions>,
  path: number[]
) => {
  const criteria = db.state.activeRule.criteria;

  const [targetSubgroup, parentSubgroup, targetIndex] = getGroupByPath(criteria, path);

  console.debug(`Deleting group ${targetSubgroup.id}`);

  parentSubgroup !== undefined &&
  targetIndex !== undefined &&
  parentSubgroup.subgroups.splice(targetIndex, 1);

  // console.debug(factStore)
  db.setState({...{activeRule: {criteria: criteria}}, ...db.state})
};

const newFact = (
  db: Store<AppDB, AppDBActions>,
  path: number[]
) => {
  const criteria = db.state.activeRule.criteria;

  const [targetSubgroup, ,] = getGroupByPath(criteria, path);

  const id = v4();

  console.debug(`Adding new fact ${id} to ${targetSubgroup.id}`);

  targetSubgroup.facts.push({
    id: id,
    concept_purl: ""
  });

  db.setState({...{activeRule: {criteria: criteria}}, ...db.state})
};

const newGroup = (
  db: Store<AppDB, AppDBActions>,
  path: number[]
) => {
  const criteria = db.state.activeRule.criteria;

  const [targetSubgroup, ,] = getGroupByPath(criteria, path);

  const id = v4();

  console.debug(`Adding new group ${id} to ${targetSubgroup.id}`);

  targetSubgroup.subgroups.push({
    id: id,
    booleanType: BooleanGroupTypes.AND,
    subgroups: [],
    facts: []
  });

  db.setState({...{activeRule: {criteria: criteria}}, ...db.state})
};

const newAction = (
  db: Store<AppDB, AppDBActions>,
  //path: number[]
) => {
  const actions = db.state.activeRule.actions;

  actions.push({
    id: v4(),
    appSection: AppSection.Checklist,
  });

  db.setState({...{activeRule: {actions: actions}}, ...db.state})
};

export const blankRule = (namespaceId: string) => {
  return {
    id: v4(),
    name: "",
    namespace: {
      id: namespaceId
    },
    criteria: {
      id: "",
      booleanType: BooleanGroupTypes.AND,
      subgroups: [],
      facts: []
    },
    actions: [],
    notes: "",
    tags: [],
    disabled: false
  }
};

const updateRules = (
  db: Store<AppDB, AppDBActions>,
  namespaceId: string
) => {
  console.debug(`Updating rules of NS ${namespaceId}`);
  getRulesByNamespace((data) => {
    console.debug(`getRulesByNamespace data:`);
    console.debug(`data type: ${typeof data}`);
    console.debug(JSON.stringify(data));
    setRulesOfActiveNS(db, data);
  }, namespaceId)
};

const initialState: AppDB = {
  activeAppFunction: AppFunction.RuleManager,
  activeNamespace: null,
  rulesOfActiveNamespace: [],
  activeRuleManagerScreen: RuleScreens.RuleManager,
  activeRule: blankRule(""),

  alerts: []
};

// const initialState: AppDB = {
//   factStore: new FactStore(),
//   testVal: "something"
// };

const actions = {
  setActiveNS,
  setRulesOfActiveNS,

  setActiveAppFunction,

  setActiveRuleManagerScreen,

  setActiveRule,
  updateRuleName,
  updateRuleByField,
  updateRules,

  newFact,
  updateFact,
  deleteFact,
  updateGroup,
  deleteGroup,
  newGroup,

  newAction,
  updateAction,
  deleteWithinAction,
  addChecklistOption,

  addAlert,
  popAlert
};

export const useGlobal = globalHook<AppDB, AppDBActions>(
  React,
  initialState,
  actions
);

/**
 * Start of Redux implementation
 */

export enum ConceptManagerTab {
  Concepts = "Concepts",
  Search = "Search"
}

const conceptManagerSlice = createSlice({
  name: 'conceptManager',
  initialState: {
    activeTab: ConceptManagerTab.Search,
    conceptToEdit: {},
    displayEditConceptModal: false,

    searchResults: null,
    searchTerms: "",
    selectedOntologies: {
      "SNOMEDCT": true,
      "NCIT": true,
      "CCTOO": true,
      "LOINC": false,
      "RXNORM": false,
      "CPT": false,
      "G-PROV": false,
      "SIO": false
    } as { [index: string]: boolean },

    displayImportConceptsModal: false,
    newConceptToImport: {
      "preferred-name": "",
      type: undefined,
      category: "",
    },
    conceptsToImport: {} as {[index: number]: BioportalConcept}
  },
  reducers: {
    selectConcept: {
      reducer(state, action) {
        const c = action.payload.concept;
        const idx = c.id;
        if (state.conceptsToImport[idx]) {
          delete state.conceptsToImport[idx];
        } else {
          state.conceptsToImport[idx] = c;
        }
        console.debug(`conceptsToImport:`);
        console.debug(state.conceptsToImport)
      },
      prepare(concept: BioportalConcept) {
        return {payload: {concept: concept}}
      }
    },

    selectAllConcepts: {
      reducer(state,action) {
        state.conceptsToImport = {};
        action.payload.forEach((c:any) => {
          state.conceptsToImport[c.id] = c;
        });
      },
      prepare(concepts: BioportalConcept[]) {
        return {payload: concepts};
      }
    },

    deselectAllConcepts: (state) => {
      state.conceptsToImport = {}
    },

    toggleSelectedOntology: {
      reducer(state, action) {
        state.selectedOntologies[action.payload] = !state.selectedOntologies[action.payload]
      },
      prepare(ontology: string) {
        return {payload: ontology}
      }
    },

    setAllSelectedOntology: {
      reducer(state, action) {
        for (const k in state.selectedOntologies) {
          state.selectedOntologies[k] = action.payload
        }
      },
      prepare(s: boolean) {
        return {payload: s}
      }
    },

    setDisplayEditConceptModal: {
      reducer(state, action) {
        state.displayEditConceptModal = action.payload
      },
      prepare(display: boolean) {
        return {payload: display}
      }
    },

    setDisplayImportConceptsModal: {
      reducer(state, action) {
        state.displayImportConceptsModal = action.payload
      },
      prepare(display: boolean) {
        return {payload: display}
      }
    },

    updateNewConceptToImport: {
      reducer(state, action) {
        (state.newConceptToImport as any)[action.payload.k]= action.payload.v;
      },
      prepare(k: "type" | "preferred-name" | "description", v: any) {
        return {payload: {k: k, v: v}}
      }
    },

    setSearchTerms: {
      reducer(state, action) {
        state.searchTerms = action.payload;
      },
      prepare(terms: any) {
        return {payload: terms};
      }
    },

    setSearchResults: {
      reducer(state, action) {
        state.searchResults = action.payload.results;
      },
      prepare(results: any) {
        console.debug(`Setting search results: ${JSON.stringify(results)}`);
        return {payload: {results: results}}
      }
    },

    setActiveTab: {
      reducer(state, action) {
        console.debug(`reducer for setActiveTab payload: ${JSON.stringify(action.payload)}`);
        state.activeTab = action.payload.activeTab
      },
      prepare(activeTab: ConceptManagerTab) {
        console.debug(`Setting ConceptManager tab: ${activeTab}`);
        return {payload: {activeTab: activeTab}}
      }
    },

    setConceptToEdit: {
      reducer(state, action) {
        const c = action.payload.concept;
        if (c.synonyms) c.synonyms = c.synonyms.sort().join('\n');
        if (c.cuis) c.cuis = c.cuis.join('\n');
        if (c.purls) c.purls = c.purls.join('\n');

        state.conceptToEdit = c;
      },
      prepare(concept: MultiPURLConcept) {
        return {payload: {concept: concept}}
      }
    },

    updateConcept: {
      reducer(state, action) {
        const key = action.payload.key;
        const value = action.payload.value;
        (state.conceptToEdit as any)[key] = value;
        console.debug(`updateConcept[${key}] -> ${value}`);
        console.debug(state.conceptToEdit)
      },
      prepare(key, value) {
        return {payload: {key: key, value: value}}
      }
    },


  }
});

export const ConceptManagerActions = conceptManagerSlice.actions;

export interface ConceptManagerState {
  activeTab: ConceptManagerTab,
  conceptToEdit?: Concept,
  displayEditConceptModal?: boolean,
  searchResults?: any[],
  searchTerms: string,
  selectedOntologies: { [index: string]: boolean },

  displayImportConceptsModal?: boolean,
  newConceptToImport: NewConceptInfo,
  conceptsToImport: {[id: string]: BioportalConcept},
}

export interface AppState {
  conceptManager: ConceptManagerState;
}

export const store = configureStore({
  reducer: combineReducers({
    conceptManager: conceptManagerSlice.reducer
  })
});
