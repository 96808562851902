import * as React from "react";
import {AppDB, AppDBActions, useGlobal} from "../AppDB";
import {Concept, Fact, NumericRelation, ObjectRelation} from "../qrm"
import {PURLTypeAhead, SelectableButton} from "../components";
import {DEBUG} from "../debug";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import * as network from "../network";
import {useEffect} from "react";
import ConceptMap from "../concepts";

const ConceptEditor: React.FC<{
  fact: Fact,
  onChange: (selected: any) => void
}> = (props) => {
  // const [fact, updateFact] = useGlobal<Fact, (id: string, key: string, value: any) => void>(
  //   (db: AppDB) => db.factStore.getById(props.factId),
  //   (actions: AppDBActions) => actions.updateFact
  // );
  return (
    <div className="form-group">
      <label>Concept:</label>
      <PURLTypeAhead
        selectedPurl={props.fact.concept_purl}
        onChange={props.onChange}
        showDescriptions={true}
      />
    </div>
  )
};

export const FactCard: React.FC<{ path: number[], fact: Fact }> = ({fact, path}) => {
  //console.debug(data);
  const [, {updateFact, deleteFact}] = useGlobal<AppDB, AppDBActions>(
    (db: AppDB) => db,
    (actions: AppDBActions) => actions
  );

  const [allConcepts, setConcepts] = useState<ConceptMap>(new ConceptMap([]));
  const [selectedConcept, setSelectedConcept] = useState<Concept>();

  useEffect(() => {
    const getConcepts = () => {
      console.debug(`Getting all concepts from concept store...`);
      network.getConcepts((concepts: Concept[]) => {
        console.debug(concepts);
        const cm = new ConceptMap(concepts);
        setConcepts(cm);

        const c = cm.getByPURL(fact.concept_purl);
        console.debug(`useEffect getByPURL(${fact.concept_purl}) -> ${JSON.stringify(c)}`);
        setSelectedConcept(c);
      })
    };

    getConcepts();
  }, [fact.concept_purl]);


  useEffect(() => {

  }, [allConcepts, setSelectedConcept, fact]);

  var instanceValueField;
  var relations: any[] = [];

  if (selectedConcept) {
    switch (selectedConcept.type) {
      case "boolean":
        relations = Object.values(ObjectRelation);
        instanceValueField =
          <div className="btn-group btn-group-sm">
            <SelectableButton label={"True"}
                              value={(fact.value === undefined) ? "zz" : (fact.value ? "True" : "False")}
                              onClick={(value) => {
                                updateFact(path, "value", (value === "True"))
                              }}/>
            <SelectableButton label={"False"}
                              value={(fact.value === undefined) ? "zz" : (fact.value ? "True" : "False")}
                              onClick={(value) => {
                                updateFact(path, "value", (value !== "False"))
                              }}/>
          </div>;
        break;
      case "string":
        relations = Object.values(ObjectRelation);
        instanceValueField =
          <div className="form-group">
            <label>Value:</label>
            <input type={"text"}
                   className={"form-control"}
                   onChange={(event) => {
                     const text = event.target.value;
                     updateFact(path, "value", text);
                   }}
            />
          </div>;
        break;
      case "purl":
        relations = Object.values(ObjectRelation);
        instanceValueField =
          <div>
            <label>Instance:</label>
            <PURLTypeAhead
              selectedPurl={fact.value}
              onChange={(selected) => {
                console.debug("Selecting PURL value:");
                console.debug(selected);
                updateFact(path, "value", (selected[0] || {}).purl)
              }}
              showDescriptions={true}
            />
          </div>;
        break;
      case "long":
        relations = Object.values(NumericRelation);
        instanceValueField =
          <div className="form-group">
            <label>Value:</label>
            <input type="number"
                   className={"form-control"}
                   onChange={(event) => {
                     const text = event.target.value;
                     updateFact(path, "value", parseInt(text));
                   }}
            />
          </div>;
        break;
      case "double":
        relations = Object.values(NumericRelation);
        instanceValueField =
          <div className="form-group">
            <label>Value:</label>
            <input type="number"
                   step="0.01"
                   className={"form-control"}
                   onChange={(event) => {
                     const text = event.target.value;
                     updateFact(path, "value", parseFloat(text));
                   }}
            />
          </div>;
        break;
      default:
        instanceValueField =
          <p>Concept type <strong><em>{selectedConcept.type}</em></strong> found. <strong>
            <em>You probably don't want to use this as part of a fact</em></strong></p>
    }
    relations.push("is undefined", "is defined");

    console.debug("Relations");
    console.debug(relations)
  }

  var valueString;
  if (allConcepts.getByPURL(fact.value)) {
    valueString = (allConcepts.getByPURL(fact.value) || {})["preferred-name"] || `ERROR - CONCEPT WITHOUT NAME (${fact.concept_purl})`;
  } else if (fact.value === undefined) {
    valueString = "???";
  } else if (fact.value === true) {
    valueString = "True";
  } else if (fact.value === false) {
    valueString = "False";
  } else if (fact.value) {
    valueString = fact.value;
  } else {
    valueString = "???";
  }

  if (fact.relation === "is undefined" || fact.relation === "is defined") {
    valueString = "";
    instanceValueField = <div/>
  }
  return (
    <div className="card fact">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col-11">
            <h5 className="my-auto">
              Fact&nbsp;
              <small className="text-muted">( <em>{`${(selectedConcept || {})['preferred-name'] || "???"}`}</em></small>
              <small className="text-muted ">{` ${fact.relation || "???"} `}</small>
              <small className="text-muted"><em>{valueString}</em> )</small>
            </h5>
            <small className="text-muted">{DEBUG ? ` Path: ${JSON.stringify(path)}` : ""}</small>
            {DEBUG ? <br/> : ""}
            <small className="text-muted">{DEBUG ? fact.id : ""}</small>
          </div>
          <div className="col-1">
            <button className="btn btn-outline-danger btn-no-border float-right"
                    onClick={() => deleteFact(path)}>
              <FontAwesomeIcon icon={faTrashAlt}/>
            </button>
          </div>
        </div>
      </div>

      <div className="card-body">
        <ConceptEditor
          fact={fact}
          onChange={(selected) => {
            console.debug("Selecting:");
            console.debug(selected);
            updateFact(path, "concept_purl", (selected[0] || {}).purl);
            updateFact(path, "value", null);
          }}
        />
        <div className="btn-group btn-group-sm mb-3">
          {
            relations.map((relation) => {
              return (
                <SelectableButton key={relation}
                                  label={relation}
                                  value={fact.relation || ""}
                                  onClick={(value) => {
                                    updateFact(path, "relation", value)
                                  }}/>
              )
            })
          }
        </div>
        <br/>
        {instanceValueField}
      </div>
    </div>
  )
};
