import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencilAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import * as network from '../network'
import {saveConcept} from '../network'
import ConceptMap from "../concepts";
import {Concept} from '../qrm';
import {ConceptTypeButtons, linkFormatter, paragraphFormatter, TabButton} from "../components";
import {ImportConceptsModal} from './ImportConcepts'

import './ConceptManager.css'
import {Typeahead} from "react-bootstrap-typeahead";
import {AppState, ConceptManagerActions, ConceptManagerState, ConceptManagerTab} from "../AppDB";
import {connect} from "react-redux";

const _SourceCheckBox: React.FC<{
  value: string
  label?: string
  className?: string
  checked?: boolean,
  onChange: () => void
}> =
  ({
     value,
     label,
     className,
     checked,
     onChange
   }) => {
    return (
      <div className={`form-check form-check-inline ${className}`}>
        <Input className="form-check-input double-size" type="checkbox" value={value} checked={checked}
               onChange={onChange}/>
        <Label className="form-check-label">
          <a target="_blank" rel="noopener noreferrer" href={`https://bioportal.bioontology.org/ontologies/${value}`}>
            {label || value}
          </a>
        </Label>
      </div>
    )
  };
const SourceCheckBox = connect()(_SourceCheckBox);

const columns = [{
  dataField: 'names',
  formatter: paragraphFormatter,
  text: 'Preferred Name',
  sort: true
}, {
  dataField: 'synonyms',
  text: 'Synonyms',
  formatter: paragraphFormatter,
  sort: true
}, {
  dataField: 'definitions',
  text: 'Definition',
  formatter: paragraphFormatter,
  sort: false
}, {
  dataField: 'cuis',
  text: 'CUI',
  formatter: paragraphFormatter,
  sort: true
}, {
  dataField: 'ontologies',
  text: 'Ontologies',
  formatter: paragraphFormatter,
  sort: true
}, {
  dataField: 'purl',
  text: 'PURL',
  sort: true,
  formatter: linkFormatter
}];

const _SearchResults: React.FC<ConceptManagerState & typeof ConceptManagerActions> =
  ({
     conceptsToImport,
     searchResults,
     selectConcept,
     selectAllConcepts,
     deselectAllConcepts,
     setDisplayImportConceptsModal,
   }) => {
    const triggerImportConcepts = () => {
      //updateNewConceptToImport('preferred-name', '');
      //updateNewConceptToImport('type', undefined);
      setDisplayImportConceptsModal(true);
    };

    if (searchResults) {
      return (
        <div>
          <hr/>
          <div className="d-flex flex-row w-100 justify-content-between mb-3">
            <h5>Found {searchResults.length} results</h5>
            {
              Object.keys(conceptsToImport).length > 0 ?
                <button className="btn btn-primary btn-sm"
                        onClick={() => {
                          triggerImportConcepts();
                        }}>
                  Import Selected as Equivalent
                </button>
                :
                <div/>
            }
          </div>
          {
            searchResults.length > 0 ?
              <BootstrapTable bootstrap4
                              columns={columns}
                              defaultSorted={[{
                                dataField: 'names',
                                order: 'asc'
                              }]}
                              keyField={'id'}
                              selectRow={{
                                mode: 'checkbox',
                                clickToSelect: true,
                                selectColumnPosition: 'right',
                                style: {
                                  backgroundColor: '#d6eaff'
                                },
                                selected: Object.keys(conceptsToImport),
                                onSelect: (row, isSelect) => {
                                  console.debug('Selecting row:');
                                  console.debug(row);
                                  console.debug(`isSelect: ${isSelect}`);
                                  selectConcept(row);
                                },
                                onSelectAll: (isSelect, rows) => {
                                  console.debug(`onSelectAll: ${isSelect}`);
                                  if (isSelect) {
                                    selectAllConcepts(rows);
                                  } else {
                                    deselectAllConcepts()
                                  }

                                  console.debug(rows);
                                }
                              }}
                              data={searchResults}/>
              :
              <div/>
          }
        </div>
      )
    } else {
      return (<div/>)
    }
  };
const SearchResults = connect(
  (state: AppState) => state.conceptManager,
  ConceptManagerActions
)(_SearchResults);

const _SearchForm: React.FC<ConceptManagerState & typeof ConceptManagerActions> =
  ({
     searchResults, setSearchResults,
     searchTerms, setSearchTerms,
     selectedOntologies, toggleSelectedOntology, setAllSelectedOntology
   }) => {

    const [searching, setSearching] = useState(false);

    const resetResults = () => {
      setSearchResults(undefined);
    };

    return (
      <div>
        <div>
          <Form>
            <FormGroup>
              <Label>Search terms:</Label>
              <Input placeholder="Search terms..."
                     value={searchTerms}
                     onChange={(event) => {
                       // clear out search results otherwise rendering is super slow
                       resetResults();
                       setSearchTerms(event.target.value);
                     }}/>
            </FormGroup>
            <FormGroup>
              {
                Object.keys(selectedOntologies).sort().map((source) => {
                  return <SourceCheckBox key={source}
                                         value={source}
                                         checked={selectedOntologies[source]}
                                         onChange={() => {
                                           resetResults();
                                           toggleSelectedOntology(source);
                                         }}/>
                })
              }
              <button className="float-right p-0 btn btn-link"
                      onClick={(e) => {
                        e.preventDefault();
                        resetResults();
                        let allTrue = true;
                        for (const k in selectedOntologies) {
                          allTrue = allTrue && selectedOntologies[k]
                        }
                        // if all true, uncheck everything; otherwise, check everything
                        setAllSelectedOntology(!allTrue);
                      }}>
                Toggle All Sources
              </button>
            </FormGroup>
          </Form>
          <div className="d-flex flex-row justify-content-end">
            {
              searching ?
                <Button disabled={true}>
                  Searching...
                </Button>
                :
                <Button disabled={searchTerms.length === 0}
                        color="primary"
                        onClick={() => {
                          setSearching(true);

                          resetResults();

                          let ontologies = [];
                          for (const k in selectedOntologies) {
                            selectedOntologies[k] && ontologies.push(k)
                          }

                          console.debug(`Searching ontologies: ${JSON.stringify(ontologies)}`);

                          network.bioportalSearchConcept(searchTerms,
                            ontologies,
                            (results) => {
                              if (results.status && results.status === 400) {
                                console.error(`Error for search terms "${searchTerms}"`)
                              } else {
                                console.debug('Search results:');
                                console.debug(results);
                                setSearchResults(results);
                              }
                              setSearching(false);
                            })
                        }}>
                  Search
                </Button>
            }
          </div>
        </div>
        {
          searchResults ?
            <SearchResults/>
            :
            <div/>
        }
      </div>
    )
  };
const SearchForm = connect(
  (state: AppState) => state.conceptManager,
  ConceptManagerActions
)(_SearchForm);

const ConceptRow: React.FC<{
  concept: Concept,
  triggerEditConcept: (concept: Concept) => void
}> = ({concept, triggerEditConcept}) => {
  return (
    <div className="concept-row row">
      <div className="col-md-5 my-auto">
        <div>
          {concept["preferred-name"]}
        </div>
        {/* supporting synonyms later...*/}
        {/*<div>*/}
        {/*  <p className="font-italic text-muted">*/}
        {/*    ({"helllo"})*/}
        {/*  </p>*/}
        {/*</div>*/}
      </div>
      <div className="col-md-6 my-auto">
        {concept['purl']}
      </div>
      <div className="col-md-1 flex-row">
        <div className="float-md-right">
          <button className="btn btn-outline-info btn-no-border"
                  onClick={() => triggerEditConcept(concept)}>
            <FontAwesomeIcon icon={faPencilAlt}/>
          </button>
        </div>
      </div>
    </div>
  )
};

const _ConceptList: React.FC<{
  conceptMap: ConceptMap
  triggerEditConcept: (concept: Concept) => void
}> = ({conceptMap, triggerEditConcept}) => {

  if (conceptMap.concepts.length === 0) {
    return (
      <h5 className="mt-3 text-muted">Loading concepts...</h5>
    )
  } else {
    return (
      <div>
        {
          conceptMap.concepts.map((concept) => {
            return (
              <ConceptRow key={concept.purl} concept={concept} triggerEditConcept={triggerEditConcept}/>
            )
          })
        }
      </div>
    )
  }
};
const ConceptList = connect()(_ConceptList);

const _EditConceptForm: React.FC<{
  concept: Concept | undefined,
  selectedOntologies: { [index: string]: boolean }
} & typeof ConceptManagerActions> =
  ({concept, updateConcept, selectedOntologies}) => {
    const preferred_name = concept ? concept["preferred-name"] : "";
    const purl = concept ? concept.purl : "";
    const description = concept ? concept.description : "";
    const cuis = concept && concept.cuis ? concept.cuis : "";
    const conceptType = concept ? concept.type : "";
    const sources = concept ? concept.sources : [];
    const synonyms = concept && concept.synonyms ? concept.synonyms : '';

    return (
      <Form>
        <FormGroup>
          <Label>Preferred name:</Label>
          <Input placeholder="Preferred/display name"
                 value={preferred_name}
                 onChange={(event) => {
                   // console.debug(`Updating concept preferred name: ${event.target.value}`);
                   updateConcept('preferred-name', event.target.value);
                 }}/>
        </FormGroup>

        <FormGroup>
          <Label>Synonyms <small>(one per line)</small>:</Label>
          <Input placeholder="Synonyms, separated by newlines"
                 type="textarea"
                 value={synonyms}
                 rows={5}
                 onChange={(event) => {
                   updateConcept('synonyms', event.target.value);
                 }}/>
        </FormGroup>

        <FormGroup>
          <Label>PURL:</Label>
          <Input placeholder="PURL"
                 value={purl}
                 onChange={(event) => {
                   updateConcept('purls', event.target.value);
                 }}
          />
        </FormGroup>

        <FormGroup>
          <Label>Description:</Label>
          <Input placeholder="Description of concept (context, how it's used, etc.)"
                 type="textarea"
                 value={description}
                 rows={3}
                 onChange={(event) => {
                   updateConcept('description', event.target.value);
                 }}/>
        </FormGroup>

        <FormGroup>
          <Label>CUIs <small>(one per line)</small>:</Label>
          <Input placeholder="Please enter CUIs, separated by newlines"
                 type="textarea"
                 value={cuis}
                 onChange={(event) => {
                   updateConcept('cuis', event.target.value);
                 }}
          />
        </FormGroup>

        <FormGroup>
          <Label>Type:</Label>
          <br/>
          <ConceptTypeButtons selected={conceptType}
                              onSelect={(type) => {
                                updateConcept('type', type);
                              }}/>
        </FormGroup>

        <FormGroup>
          <Label>Sources:</Label>
          <Typeahead id="sources"
                     options={Object.keys(selectedOntologies).concat(["qlaro", "klarahealth"])}
                     multiple={true}
                     allowNew={false}
                     selected={sources}
                     onChange={(selected: string[]) => {
                       console.debug(`Sources selected: ${JSON.stringify(selected)}`);
                       updateConcept('sources', selected);
                     }}
          />
        </FormGroup>

      </Form>
    )
  };
const EditConceptForm = connect(
  (state: AppState) => {
    return {concept: state.conceptManager.conceptToEdit}
  },
  ConceptManagerActions
)(_EditConceptForm);

const EditConceptModal: React.FC<{
  displayEditConceptModal: boolean | undefined,
  toggleEditModal: () => void,
  selectedOntologies: { [index: string]: boolean },
  conceptToEdit: Concept | undefined,
  setDisplayEditConceptModal: (b: boolean) => void
}> =
  ({
     displayEditConceptModal,
     toggleEditModal,
     selectedOntologies,
     conceptToEdit,
     setDisplayEditConceptModal
   }) => {
    return (
      <Modal className="w-100 modal-dialog modal-xl"
             centered={true}
             isOpen={displayEditConceptModal} toggle={toggleEditModal}>
        <ModalHeader>Edit Concept</ModalHeader>
        <ModalBody className="w-100">
          <EditConceptForm selectedOntologies={selectedOntologies}/>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <button className="btn btn-outline-danger btn-no-border">
            <FontAwesomeIcon icon={faTrashAlt}/>
          </button>
          <div className="flex-row">
            <Button className="mr-2"
                    color="info"
                    onClick={() => {
                      console.debug(`Saving concept...`);
                      console.debug(conceptToEdit);
                      if (conceptToEdit) {
                        const c = {...conceptToEdit};

                        if (c.synonyms)
                          c.synonyms = (c.synonyms as unknown as string).split('\n');
                        if (c.cuis)
                          c.cuis = (c.cuis as unknown as string).split('\n');

                        saveConcept(c, () => {
                          console.debug(`Finished saving concept...`)
                        })
                      } else {
                        console.error(`Error saving undefined concept... is modal even displayed?`);
                      }
                    }}>
              Save
            </Button>
            <Button color="danger"
                    onClick={() => setDisplayEditConceptModal(false)}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  };

const _ConceptManager: React.FC<ConceptManagerState & typeof ConceptManagerActions> =
  ({
     activeTab,
     setActiveTab,
     conceptToEdit,
     setConceptToEdit,
     displayEditConceptModal,
     setDisplayEditConceptModal,
     selectedOntologies
   }) => {
    const [allConcepts, setConcepts] = useState<ConceptMap>(new ConceptMap([]));

    const getConcepts = () => {
      console.debug(`Getting all concepts from concept store...`);
      network.getConcepts((concepts: Concept[]) => {
        console.debug(concepts);
        setConcepts(new ConceptMap(concepts));
      })
    };

    useEffect(() => {
      getConcepts();
    }, []);

    const toggleEditModal = () => setDisplayEditConceptModal(!displayEditConceptModal);

    const triggerEditConcept = (concept: Concept) => {
      setDisplayEditConceptModal(true);

      const multiPURLConcept = {...concept, ...{purls: [concept['purl']]}};

      setConceptToEdit(multiPURLConcept);
    };

    var activeTabDiv;
    switch (activeTab) {
      case ConceptManagerTab.Concepts:
        activeTabDiv = <ConceptList conceptMap={allConcepts} triggerEditConcept={triggerEditConcept}/>;
        break;
      case ConceptManagerTab.Search:
        activeTabDiv = <SearchForm/>;
        break;
      default:
        activeTabDiv = <div>INVALID TAB SELECTED: {activeTab}</div>
    }

    return (
      <div className="container mb-4 pt-4">
        <EditConceptModal conceptToEdit={conceptToEdit}
                          displayEditConceptModal={displayEditConceptModal}
                          selectedOntologies={selectedOntologies}
                          toggleEditModal={toggleEditModal}
                          setDisplayEditConceptModal={setDisplayEditConceptModal}
        />
        <ImportConceptsModal/>
        <nav className="mt-3 w-100">
          <div className="nav nav-tabs" id='nav-tab' role='tablist'>
            <TabButton tab={ConceptManagerTab.Concepts} activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabButton tab={ConceptManagerTab.Search} activeTab={activeTab} setActiveTab={setActiveTab}/>
          </div>
        </nav>
        <div className="tab-content w-100" id="nav-tabContent">
          <div className=" p-2 tab-pane fade show active" id="nav-facts" aria-labelledby="nav-facts-tab">
            {activeTabDiv}
          </div>
        </div>
      </div>
    )
  };
export const ConceptManager = connect(
  (state: AppState) => state.conceptManager,
  ConceptManagerActions
)(_ConceptManager);
