//import {DateTime, Integer} from "neo4j-driver/types/v1";
import * as Neo4j from "neo4j-driver"


type URL = string;

export enum BooleanGroupTypes {
  AND = "AND",
  OR = "OR"
}

export enum ObjectRelation {
  EQUAL_TO = "is equal to",
  NOT_EQUAL_TO = "is not equal to"
}

export enum NumericRelation {
  EQUALS = "=",
  NOT_EQUALS = "!=",
  LESS_THAN = "<",
  LESS_THAN_EQUAL = "≤",
  GREATER_THAN = ">",
  GREATER_THAN_EQUAL = "≥"

}

export enum RuleField {
  NAME = 'name',
  NOTES = 'notes',
  TAGS = 'tags',
  DISABLED = 'disabled'
}

export enum Categories {
  Checklist = "Checklist"
}

export enum AppSection {
  Checklist = "Checklist",
  News = "News",
  FlashMessage = "Flash Message"
}

export enum PromptType {
  RadioButton = "Radio",
  Checkbox = "Checkbox",
  NumericEntry = "Numeric",
  TextEntry = "Text",
  ParagraphEntry = "Paragraph"
}

export enum FlashMessageType {
  INFO = "Info",
  WARNING = "Warning",
  ERROR = "Error"
}

export interface Namespace {
  id: string;
  name: string;
}

export interface Prompt {
  text: string;
}

export interface ChecklistOption {
  text?: string;
  value?: string;
}

export interface ChecklistAction extends Action {
  prompt: string;
  concept_purl: string;
  type: PromptType;
  options?: ChecklistOption[]
}

export interface FlashMessageAction extends Action {
  title: string;
  description: string;
  type: FlashMessageType;
  duration: number;
}

export interface NewsAction extends Action {
  title: string;
  snippet: string;
  url: URL;
  date_published: string
}

export interface BooleanGroup {
  id: string;
  booleanType: BooleanGroupTypes;
  subgroups: BooleanGroup[];
  facts: Fact[];
}

export interface Fact {
  id: string;
  concept_purl: string;
  relation?: string
  value?: any
}

export interface Action {
  id: string;
  //category: Categories;
  appSection: AppSection;
}

export interface NamespaceReference {
  id: string;
}

export type Rule = {
  id: string;
  name: string;
  criteria: BooleanGroup;
  actions: Action[];
  namespace: NamespaceReference;
  notes: string;
  tags: string[];
  disabled: boolean;
}

export interface Alert {
  color: string;
  message: string;
  timeout?: number;
}

export type PathElement = (number | string)

export enum ConceptType {
  PURL = "purl",
  STRING = "string",
  META_CONCEPT = "meta-concept",
  DOUBLE = "double",
  LONG = "long",
  BOOLEAN = "boolean",
  BIOPORTAL = "bioportal-import"
}

export const stringToConceptType = {
  purl: ConceptType.PURL,
  string: ConceptType.STRING,
  'meta-concept': ConceptType.META_CONCEPT,
  double: ConceptType.DOUBLE,
  boolean: ConceptType.BOOLEAN,
  long: ConceptType.LONG
};

export type PURL = string;
export type CUI = string;

export type Concept = {
  purl: PURL;
  type: ConceptType | undefined ;
  cuis: CUI[];
  "preferred-name": string;
  description?: string;
  sources: string[];
  created?: Neo4j.DateTime | Neo4j.DateTime<number> | Neo4j.DateTime<Neo4j.Integer>;
  synonyms?: string[];
};

export interface MultiPURLConcept extends Omit<Concept, 'purl'> {purls: string[]}

export interface NewConceptInfo {
  'preferred-name': string,
  type: ConceptType,
  category: string,
  description: string,
}

export interface BioportalConcept {
  id: string,
  names: string[],
  synonyms: string[],
  cuis: string[],
  definitions: string[],
  purl: string,
  mappingsLinks?: string[],
  ontologies: string[],
}

export enum Relationship {
  IS_A = "IS_A"
}

export enum Neo4jFunction {
  SEARCH_BY_PURL = "searchByPURL"
}

export enum Neo4jStatus {
  NOT_FOUND = "notfound",
  SUCCESS = "success",
  ERROR = "error"
}

export interface Neo4jError {
  status: Neo4jStatus
  errorType: string,
  source: Neo4jFunction
}

export interface Neo4jSuccess {
  status: Neo4jStatus,
  source: Neo4jFunction,
  concept?: Concept,
}

export type Neo4jResult = Neo4jSuccess | Neo4jError
