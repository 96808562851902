import * as React from 'react';
import {useEffect, useState} from 'react';

import {Provider} from 'react-redux';

import SideNav, {Nav, NavIcon, NavItem, NavText, Toggle} from '@trendmicro/react-sidenav';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import './App.css';

import {AppDB, AppDBActions, AppFunction, store, useGlobal} from "./AppDB";
import {RuleManager} from "./RuleManager/RuleManager";
import {Alert} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderOpen, faLightbulb} from "@fortawesome/free-solid-svg-icons";
import {ConceptManager} from "./ConceptManager/ConceptManager";

const VERSION_STRING = 'v0.3.1';

const TimeoutAlert: React.FC<{
  color: string,
  timeout?: number,
  message: string,
  onTimeout: () => void
}> = (props) => {
  const [visible,] = useState(true);

  useEffect(() => {
    if (props.timeout) {
      window.setTimeout(() => {
        props.onTimeout()
      }, props.timeout)
    }
  }, [props]);

  return (
    <Alert className="pt-3 pb-3" color={props.color} isOpen={visible}>{props.message}</Alert>
  )
};

const App: React.FC = () => {
  const [{activeAppFunction, alerts}, {popAlert, setActiveAppFunction}] = useGlobal<AppDB, AppDBActions>(
    (db: AppDB) => db,
    (actions: AppDBActions) => actions
  );

  var active;
  switch (activeAppFunction) {
    case AppFunction.ConceptManager:
      active = <ConceptManager/>;
      break;
    case AppFunction.RuleManager:
      active = <RuleManager/>;
      break;
    default:
      active = "INVALID APP FUNCTION";
  }

  var alert;
  if (alerts.length === 0) {
    // empty alert to protect spacing
    alert = <div/>
  } else {
    const a = alerts.pop();
    alert = <TimeoutAlert color={a!.color} timeout={a!.timeout} message={a!.message}
                          onTimeout={() => popAlert()}/>
  }

  return (
    <Provider store={store}>
      <div>
        <SideNav
          componentClass="navbar"
          onSelect={(selected: "rm" | "cm") => {
            // Add your code here
            console.debug(`navbar select: ${selected}`);
            switch (selected) {
              case "rm":
                setActiveAppFunction(AppFunction.RuleManager);
                break;
              case "cm":
                setActiveAppFunction(AppFunction.ConceptManager);
                break;
            }
          }}
        >
          <Toggle/>
          <Nav defaultSelected={activeAppFunction === AppFunction.ConceptManager ? "cm" : "rm"}>
            <NavItem navitemStyle={{"color": "purple"}} eventKey="cm">
              <NavIcon>
                <FontAwesomeIcon className="nav-text navbar-icon" icon={faLightbulb}/>
              </NavIcon>
              <NavText>
                <span className="nav-text">Concept Manager</span>
              </NavText>
            </NavItem>
            <NavItem eventKey="rm">
              <NavIcon>
                <FontAwesomeIcon className="navbar-icon" icon={faFolderOpen}/>
              </NavIcon>
              <NavText>
                Rule Manager
              </NavText>
            </NavItem>
          </Nav>
        </SideNav>

        <div className="App">
          <header className="App-header">
            <div className="container d-flex flex-row justify-content-between">
              <p className="m-0">
                qlaro rule manager
              </p>
              <code className="m-0">{VERSION_STRING}</code>
            </div>
          </header>
          <div className="fixed-top">
            {alert}
          </div>
          <div className="App-body">
            {active}
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default App;
