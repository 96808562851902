import * as React from "react";
import {AppDB, AppDBActions, useGlobal} from "../AppDB";
import {
  Action,
  AppSection,
  ChecklistAction,
  ChecklistOption, FlashMessageAction,
  FlashMessageType,
  NewsAction,
  PathElement,
  PromptType,
  Rule,
} from "../qrm";
import {PURLTypeAhead, SelectableButton} from '../components'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";

import {DEBUG} from "../debug";
import {Input} from "reactstrap";

const ChecklistPromptOption: React.FC<{
  path: PathElement[],
  option: ChecklistOption
}> = (props) => {
  const [, {deleteWithinAction, updateAction}] = useGlobal<Action[], AppDBActions>(
    (db: AppDB) => db.activeRule.actions,
    (actions: AppDBActions) => actions
  );

  const option = props.option;

  return (
    <div className="row ml-0">
      <div className="col-6 p-0 pr-1">
        <input className="form-control"
               type="text"
               value={option.text}
               onChange={((event) => {
                 updateAction(props.path, ["text"], event.target.value)
               })}
        />
      </div>
      <div className="col-6 p-0 d-flex flex-row">
        <PURLTypeAhead className="w-100 p-0"
                       selectedPurl={option.value || ""}
                       onChange={(selected) => {
                         updateAction(props.path, ["value"], (selected[0] || {}).purl)
                       }}
                       showDescriptions={true}
        />
        <button className="btn btn-outline-danger btn-no-border float-right"
                onClick={() => {
                  deleteWithinAction(props.path);
                }}>
          <FontAwesomeIcon icon={faTrashAlt}/>
        </button>
      </div>

      <small className="text-muted">
        {DEBUG ? `Path ${JSON.stringify(props.path)}` : ""}
      </small>
    </div>
  )
};

const OptionsList: React.FC<{
  path: PathElement[],
  options: ChecklistOption[]
}> = (props) => {
  const [, {addChecklistOption}] = useGlobal<Action[], AppDBActions>(
    (db: AppDB) => db.activeRule.actions,
    (actions: AppDBActions) => actions
  );

  const options = props.options;

  return (
    <div>
      <div className="row mb-3">
        <label className="col-2">Options:</label>
        <button className="btn btn-sm btn-primary"
                onClick={() => {
                  addChecklistOption(props.path)
                }}>
          Add Option
        </button>
      </div>

      <div className="row">
        <h6 className="col-6">Text</h6>
        <h6 className="col-6">Value</h6>
      </div>

      {
        options && options.map((option, index) => {
          return (
            <ChecklistPromptOption path={props.path.concat(index)} option={option} key={index}/>
          )
        })
      }
    </div>
  )
};

const FlashLabel: React.FC<{
  label: string,
  className?: string
}> = (props) => {
  return (
    <p className={props.className ? props.className : "p-0 m-0 mt-2"}><em>{props.label}</em></p>
  )
};


const NewsActionCard: React.FC<{
  path: PathElement[],
  action: NewsAction
}> = (props) => {
  const [, {updateAction}] = useGlobal<AppDB, AppDBActions>(
    (db: AppDB) => db,
    (actions: AppDBActions) => actions
  );

  const action = props.action;

  return (
    <div className="form-group row">
      <label className="col-2 col-form-label">News Article:</label>
      <div className="col-10 p-0">
        <FlashLabel label="Title"/>
        <Input className="form-control" type="text"
               value={action.title}
               onChange={(event) => {
                 updateAction(props.path, ['title'], event.target.value);
               }}/>
        <FlashLabel label="Snippet"/>
        <Input className="form-control" type="text"
               value={action.snippet}
               onChange={(event) => {
                 updateAction(props.path, ['snippet'], event.target.value);
               }}/>
        <FlashLabel label="URL"/>
        <Input className="form-control" type="text"
               value={action.url}
               onChange={(event) => {
                 updateAction(props.path, ['url'], event.target.value);
               }}/>
        <FlashLabel label="Date Published"/>
        <Input className="form-control" type="date"
               value={action.date_published}
               onChange={(event) => {
                 updateAction(props.path, ['date_published'], event.target.value);
               }}/>
      </div>
    </div>
  )
};

const FlashMessageActionCard: React.FC<{
  path: PathElement[],
  action: FlashMessageAction
}> = (props) => {
  const [, {updateAction}] = useGlobal<AppDB, AppDBActions>(
    (db: AppDB) => db,
    (actions: AppDBActions) => actions
  );

  const action = props.action;

  return (
    <div className="form-group row">
      <label className="col-2 col-form-label">Flash Message:</label>
      <div className="col-10 p-0">
        <FlashLabel label="Title"/>
        <Input className="form-control" type="text"
               value={action.title}
               onChange={(event) => {
                 updateAction(props.path, ['title'], event.target.value);
               }}/>
        <FlashLabel label="Description"/>
        <Input className="form-control" type="text"
               value={action.description}
               onChange={(event) => {
                 updateAction(props.path, ['description'], event.target.value);
               }}/>
        <div className="d-flex flex-row">
          <div>
            <FlashLabel label="Message Type:"/>
            <div className="btn-group btn-group-sm mt-1">
              {
                Object.values(FlashMessageType).map((type) => {
                  return (
                    <SelectableButton label={type}
                                      selectedButtonClass="btn-primary"
                                      defaultButtonClass="btn-outline-primary"
                                      value={action.type}
                                      onClick={(value) => {
                                        updateAction(props.path, ["type"], value);
                                      }}
                                      key={type}
                    />
                  )
                })
              }
            </div>
          </div>

          <div className="ml-5">
            <FlashLabel label="Duration (ms)"/>
            <Input className="form-control" type="number"
                   value={action.duration || 3000}
                   onChange={(event) => {
                     updateAction(props.path, ['duration'], parseInt(event.target.value));
                   }}
            />
          </div>

        </div>
      </div>
    </div>
  )
};

const ChecklistActionCard: React.FC<{
  path: PathElement[]
  action: ChecklistAction
}> = (props) => {
  const [, {updateAction}] = useGlobal<AppDB, AppDBActions>(
    (db: AppDB) => db,
    (actions: AppDBActions) => actions
  );

  const action = props.action;
  const options = action.options;

  var entry;

  switch (action.type) {
    case PromptType.RadioButton:
    case PromptType.Checkbox:
      entry = <OptionsList path={props.path.concat("options")} options={options || []}/>;
      break;
    case PromptType.NumericEntry:
      entry = <div/>;
      break;
    case PromptType.TextEntry:
      entry = <div/>;
      break;
    case PromptType.ParagraphEntry:
      entry = <div/>;
      break;
    default:
      entry = <div/>;
  }

  return (
    <div>
      <div className="form-group row">
        <label className="col-2 col-form-label">Prompt:</label>
        <input type="text"
               className="col-10 form-control"
               value={action.prompt}
               onChange={(event) => {
                 const text = event.target.value;
                 updateAction(props.path, ["prompt"], text);
               }}
        />
      </div>

      <div className="form-group row">
        <label className="col-2 col-form-label">Concept:</label>
        <PURLTypeAhead className="col-10 p-0"
                       selectedPurl={action.concept_purl}
                       onChange={(selected) => {
                         console.debug(`Selected concept: ${JSON.stringify(selected)}`);
                         updateAction(props.path, ["concept_purl"], (selected[0] || {}).purl)
                       }}
                       showDescriptions={true}
        />
      </div>

      <div className="form-group row">
        <label className="col-2 col-form-label">Type:</label>
        <div className="btn-group btn-group-sm">
          {
            Object.values(PromptType).map((type) => {
              return (
                <SelectableButton label={type}
                                  selectedButtonClass="btn-primary"
                                  defaultButtonClass="btn-outline-primary"
                                  value={action.type}
                                  onClick={(value) => {
                                    updateAction(props.path, ["type"], value);
                                  }}
                                  key={type}
                />
              )
            })
          }
        </div>
      </div>

      {entry}
    </div>
  )
};

const CardBody: React.FC<{
  path: PathElement[],
  action: Action
}> = (props) => {
  const [, {deleteWithinAction, updateAction}] = useGlobal<AppDB, AppDBActions>(
    (db: AppDB) => db,
    (actions: AppDBActions) => actions
  );

  const action = props.action;

  var prompt: any;

  switch (action.appSection) {
    case AppSection.Checklist:
      prompt = <ChecklistActionCard path={props.path} action={action as ChecklistAction}/>;
      break;
    case AppSection.News:
      prompt = <NewsActionCard path={props.path} action={action as NewsAction}/>;
      break;
    case AppSection.FlashMessage:
      prompt = <FlashMessageActionCard path={props.path} action={action as FlashMessageAction}/>;
      break;
    default:
      prompt = "SOMETHING IS WRONG"
  }


  return (
    <div className="card mt-3 w-100">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col-11">
            <h5 className="my-auto">Action</h5>
            <small>{DEBUG ? ` Path: ${JSON.stringify(props.path)}` : ""}</small>
            {DEBUG ? <br/> : ""}
            <small>{DEBUG ? action.id : ""}</small>
          </div>
          <div className="col-1">
            <button className="btn btn-outline-danger btn-no-border float-right"
                    onClick={() => {
                      deleteWithinAction(props.path);
                    }}>
              <FontAwesomeIcon icon={faTrashAlt}/>
            </button>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="form-group row">
          <label className="col-2 col-form-label">App Section:</label>
          <div className="btn-group btn-group-sm">
            {
              Object.values(AppSection).map((section) => {
                return (
                  <SelectableButton label={section}
                                    selectedButtonClass="btn-primary"
                                    defaultButtonClass="btn-outline-primary"
                                    value={action.appSection}
                                    onClick={(value) => {
                                      updateAction(props.path, ["appSection"], value)
                                    }}
                                    key={section}
                  />
                )
              })
            }
          </div>
        </div>

        {prompt}

      </div>
    </div>
  )
};

export const ActionCard: React.FC<{
  activeRule: Rule,
  newAction: () => void
}> = (props) => {
  const activeRule = props.activeRule;
  const newAction = props.newAction;
  return (
    <div className="mb-3 w-100">
      {activeRule.actions.length === 0 ?
        <h5 className="text-muted p-3">No actions defined</h5>
        :
        activeRule.actions.map((action, index) => {
          return (
            <CardBody path={[index]} action={action} key={action.id}/>
          )
        })
      }
      <button className="ml-2 mt-3 btn btn-sm btn-secondary float-right"
              onClick={() => {
                newAction()
              }}>
        Add Action
      </button>
    </div>
  )
};