import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStickyNote, faTimes} from '@fortawesome/free-solid-svg-icons'

import {AppDB, AppDBActions, blankRule, RuleScreens, useGlobal} from "../AppDB";
import {Namespace, Rule} from "../qrm";
import {createNamespace, deployRules, getNamespace, getRulesByNamespace} from "../network";
import {GuidedRuleEditor} from "./GuidedRuleEditor";

export const RuleManager: React.FC = () => {
  const [{activeRuleManagerScreen}] = useGlobal<AppDB, AppDBActions>(
    (db: AppDB) => db,
    (actions: AppDBActions) => actions
  );

  switch (activeRuleManagerScreen) {
    case RuleScreens.RuleManager:
      return <NamespaceScreen/>;
    case RuleScreens.GuidedRuleEditor:
      return <GuidedRuleEditor/>;
    default:
      return <div className="p-3">Invalid RuleManager screen requested</div>;
  }
};

export const NamespaceScreen: React.FC = () => {
  const [{activeNamespace}, {setActiveRule, setActiveRuleManagerScreen, setActiveNS}] = useGlobal<AppDB, AppDBActions>(
    (db: AppDB) => db,
    (actions: AppDBActions) => actions
  );

  const [isDeploying, setIsDeploying] = useState(false);
  const [deployRulesResponse, setDeployRulesResponse] = useState<string>("");

  const [ruleDeploymentErrors, setRuleDeploymentErrors] = useState(null);

  const [namespaceModal, setNamespaceModal] = useState(false);
  const [nsName, setNSName] = useState("");

  const [namespaces, setNamespaces] = useState<Namespace[]>([]);

  const [rules, setRules] = useState<Rule[]>([]);

  const updateNamespaces = () => {
    getNamespace((data) => {
      console.debug(`getNamespace data:`);
      console.debug(JSON.stringify(data));
      setNamespaces(data);
    })
  };

  const updateRules = (namespaceId: string) => {
    console.debug(`Updating rules of NS ${namespaceId}`);
    setRules([]);
    getRulesByNamespace((data) => {
      console.debug(`getRulesByNamespace data:`);
      console.debug(JSON.stringify(data));
      setRules(data);
    }, namespaceId)
  };

  useEffect(() => {
    console.debug('Calling RuleManager effect...');
    updateNamespaces();
    console.debug(`activeNamespace? ${activeNamespace}`);
    if (activeNamespace) updateRules(activeNamespace.id);
  }, [activeNamespace]);

  const toggleNamespaceModal = () => setNamespaceModal(!namespaceModal);

  const isActive = (activeNamespace: Namespace | null, ns: Namespace) => {
    if (activeNamespace && activeNamespace.id === ns.id)
      return "active";
  };

  return (
    <div className="RulesManager container mb-4 pt-4">
      <Modal className=""
             centered={true}
             isOpen={namespaceModal} toggle={toggleNamespaceModal}>
        <ModalHeader>Create New Namespace</ModalHeader>
        <ModalBody>
          <Input placeholder="Please enter a name"
                 value={nsName}
                 onChange={(event) => setNSName(event.target.value)}/>
        </ModalBody>
        <ModalFooter>
          <Button color="info"
                  onClick={() => {
                    const ns = nsName.trim();
                    createNamespace(ns, () => {
                      updateNamespaces();
                      setNSName("");
                      toggleNamespaceModal();
                    });
                  }}>
            Create
          </Button>
          <Button color="danger"
                  onClick={() => setNamespaceModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <div className="row">
        <div className="col-md-4 col-sm-12 mb-3">
          <h4 className="p-0">Namespaces</h4>
          {
            namespaces.length === 0 ?
              <h5 className="text-muted">Loading data...</h5>
              :
              <div/>
          }
          <ul className="list-group">
            {
              namespaces.sort((a, b) => a.name.localeCompare(b.name)).map((ns) => {
                return (
                  <button className={`list-group-item list-group-item-action ${isActive(activeNamespace, ns)}`}
                          key={ns.id}
                          onClick={() => {
                            setActiveNS(ns);
                            updateRules(ns.id);
                          }}>
                    {ns.name}
                  </button>
                );
              })
            }
          </ul>
          <div className="mt-4">
            <button className="btn btn-sm btn-primary"
                    onClick={toggleNamespaceModal}>
              New Namespace
            </button>
          </div>
        </div>
        <div className="col-md-8 col-sm-12">
          <h4 className="p-0 border-bottom pb-3 mb-3">Rules</h4>
          {activeNamespace ?
            <div>
            <div className="d-flex justify-content-between">
              <button className="btn btn-sm btn-info"
                      onClick={() => {
                        setActiveRule(blankRule(activeNamespace.id));
                        setActiveRuleManagerScreen(RuleScreens.GuidedRuleEditor)
                      }}>
                New Rule
              </button>

              <button className="btn btn-sm btn-primary"
                disabled={isDeploying}
                onClick={() => {
                  setIsDeploying(true);
                  setDeployRulesResponse("");

                  deployRules(activeNamespace.id, (body) => {
                    setIsDeploying(false);
                    if (body['num-rules']) {
                      setDeployRulesResponse(`Deployed ${body['num-rules']} rules to Rule Engine`);
                      setRuleDeploymentErrors(null);
                    }
                    else {
                      setDeployRulesResponse(`Error deploying rules.  See below for errors.`);
                      setRuleDeploymentErrors(body);
                    }
                  });
                }}
              >
            {isDeploying ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {' '}Deploying...
                </>
              ) : (
                'Deploy Namespace to Rule Engine'
              )}  
              </button>
              
            </div>
            {
                deployRulesResponse.length > 0 ?
                  <div className="d-flex flex-row-reverse">
                    {deployRulesResponse}
                    <span className="mr-2 cursor-pointer" color="link" onClick={() => setDeployRulesResponse("")}>
                      <FontAwesomeIcon color="red" icon={faTimes} />
                    </span>
                  </div>
                  :
                  <div/>
              }
            </div>
            :
            <div/>
          }
          <div className="mt-3"/>
          {rules.length === 0 ?
            <h5
              className="text-muted">{activeNamespace ? "No rules defined for this namespace" : "No namespace selected"}</h5>
            :
            <ul className="list-group">
              {
                rules.sort((a, b) => a.name.localeCompare(b.name)).map((rule) => {
                  return (
                    <button className={`list-group-item list-group-item-action ${isActive(activeNamespace, rule)}`}
                            key={rule.id}
                            onClick={() => {
                              setActiveRule(rule);
                              setActiveRuleManagerScreen(RuleScreens.GuidedRuleEditor);
                            }}>
                      {
                        rule.disabled ?
                          <span className="font-italic red">(Disabled) {rule.name}</span>
                          :
                          <span className="">{rule.name}</span>
                      }
                      <br/>
                      <span className="mt-3 small">{rule.id}</span>
                      {rule.notes && rule.notes.length > 0 ?
                        <FontAwesomeIcon className="float-right" icon={faStickyNote}/> : ""}
                      {
                        rule.tags && rule.tags.length > 0 ?
                          <h6 className="m-0 p-0">
                            {rule.tags.map((tag) => {
                              return (
                                <span key={tag.toString()} className="badge badge-success mr-1">{tag.toString()}</span>
                              )
                            })}
                          </h6>
                          :
                          ""
                      }

                    </button>
                  );
                })
              }
            </ul>
          }
        </div>
        {ruleDeploymentErrors ?
          <div className="alert alert-danger mt-3">
            <h5>Error deploying rules</h5>
            <pre>{ruleDeploymentErrors}</pre>
          </div>
          :
          <div/>
        }
      </div>

      {/*<button className="btn btn-primary"*/}
      {/*        onClick={() => {*/}
      {/*          setActiveRule(blankRule());*/}
      {/*          setActiveRuleManagerScreen(Screens.GuidedRuleEditor)*/}
      {/*        }}>*/}
      {/*  New Rule*/}
      {/*</button>*/}

    </div>
  )
};