import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {Highlighter, Typeahead} from "react-bootstrap-typeahead";
import v4 from "uuid/v4";
import {useEffect, useState} from "react";
import {Concept, ConceptType} from "./qrm";
import * as network from './network'
import ConceptMap from "./concepts";

export const TabButton: React.FC<{
  tab: any,
  activeTab: any,
  setActiveTab: (tab: any) => void
}> = (props) => {
  const tab = props.tab;
  const activeTab = props.activeTab;
  const setActiveTab = props.setActiveTab;
  return (
    <button className={`mr-1 nav-item nav-link ${activeTab === tab ? "active" : ""}`} id='nav-facts-tab'
            data-toggle='tab'
            role="tab" aria-controls="nav-facts" aria-selected="true"
            onClick={(e) => {
              e.preventDefault();
              setActiveTab(tab);
            }}>
      <h5 className={activeTab === tab ? "" : "text-muted"}>{tab}</h5>
    </button>
  )
};

const SubItems: React.FC<{
  title: string,
  items: string[] | undefined,
  searchText: string | undefined
}> = ({title, items, searchText}) => {
  if (items && items.length > 1) {
    return (
      <div className="mb-1 d-flex flex-column">
        <p className="small pb-0 mb-1"><u>{title}</u></p>
        {
          items.map((p) => {
            return (
              <div className="small">
                <Highlighter search={searchText}>
                  {p}
                </Highlighter>
              </div>
            )
          })
        }
      </div>
    )
  } else {
    return <div/>
  }
};

export const PURLTypeAhead: React.FC<{
  /* prop type defs */
  selectedPurl: string,
  onChange: (selected: any) => void,
  showDescriptions: boolean,
  className?: string
}> = ({selectedPurl, className, onChange, showDescriptions}) => {

  const [allConcepts, setConcepts] = useState<ConceptMap>(new ConceptMap([]));
  const [selectedConcept, setSelectedConcept] = useState<Concept>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getConcepts = () => {
    console.debug(`Getting all concepts from concept store...`);
    network.getConcepts((concepts: Concept[]) => {
      console.debug(concepts);
      setConcepts(new ConceptMap(concepts));
    })
  };

  useEffect(() => {
    getConcepts();
  }, []);


  useEffect(() => {
    const c = allConcepts.getByPURL(selectedPurl);
    setSelectedConcept(c);
    setIsLoading(false);
  }, [allConcepts, selectedPurl]);

  return (
    <div className={className}>
      <div className="input-group">
        <div className="input-group-prepend">
                  <span className="input-group-text">
                    <FontAwesomeIcon icon={faSearch}/>
                  </span>
        </div>
        <Typeahead id={v4()}
                   isLoading={isLoading}
                   options={allConcepts.asList()}
                   labelKey={(option: Concept) => {
                     return `${option['preferred-name']}${option.synonyms && option.synonyms.length > 0 ? ', ' + option.synonyms.join(', ') : ''}, ${option.purl}`;
                   }}
                   renderMenuItemChildren={(option, props) => {
                     return (
                       <div className="d-flex flex-column border-bottom pb-3">
                         <div className="mb-1">
                           <Highlighter search={props.text}>
                             {`${option['preferred-name']}`}
                           </Highlighter>
                         </div>

                         <div className="mb-1 d-flex flex-column">
                           <Highlighter search={props.text}>
                             {option.purl}
                           </Highlighter>
                         </div>

                         <SubItems title="Synonyms:" items={option.synonyms} searchText={props.text}/>

                         <SubItems title="Mapped PURLs:" items={[option.purl]} searchText={props.text}/>
                       </div>
                     )
                   }}
                   onChange={(data) => {
                     if (Array.isArray(data) && data.length > 0) {
                       onChange(data)
                     } else {
                       console.trace(`Typeahead onChange: ${JSON.stringify(data)}`);
                     }

                   }}
                   selected={selectedConcept ? [selectedConcept] : []}/>
      </div>
      <div className="m-0">
        <p className="m-0"><em>{showDescriptions && selectedConcept ? selectedConcept.description : ""}</em></p>
      </div>
    </div>
  );
};


export const SelectableButton: React.FC<{
  label: string,
  value: string,
  onClick: (label: string) => void
  selectedButtonClass?: string,
  defaultButtonClass?: string
}> = (props) => {
  const classStr = `btn ${
    props.value === props.label
      ? (props.selectedButtonClass || "btn-secondary")
      : (props.defaultButtonClass || "btn-outline-secondary")
  }`;
  return (
    <button type="button"
            className={classStr}
            onClick={() => props.onClick(props.label)}
    >{props.label}</button>
  )
};

type T = any;

export const SelectableButtonGroup: React.FC<{
  options: T[],
  selected: T,
  onSelect: (t: T) => void
  otherOption?: T
}> = ({options, selected, onSelect, otherOption}) => {
  let allOptions = options.sort((a, b) => a.localeCompare(b));

  if (otherOption) {
    allOptions = allOptions.concat([otherOption]);
  }

  return (
    <div className="btn-group btn-group-sm mt-1">
      {
        allOptions
          .map((type) => {
            return (
              <SelectableButton label={type}
                                selectedButtonClass="btn-primary"
                                defaultButtonClass="btn-outline-primary"
                                value={selected ? selected : ''}
                                onClick={() => {
                                  onSelect(type);
                                }}
                                key={type}
              />
            )
          })

      }
    </div>
  )
};

export const paragraphFormatter = (cell: any) => {
  if (cell) {
    return (
      cell.map((s: any) => {
        return <p key={s}>{s}</p>
      })
    )
  } else {
    return cell;
  }
};

export const linkFormatter = (s: any) => {
  if (s) {
    return <a key={s}
              className="purl-link"
              href={s}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation()
              }}>
      {s}
    </a>
  } else {
    return s;
  }
};

export const ConceptTypeButtons: React.FC<{
  selected: ConceptType | undefined | "",
  onSelect: (t: ConceptType) => void
}> = ({selected, onSelect}) => {
  return (
    <SelectableButtonGroup options={Object.values(ConceptType)}
                           selected={selected}
                           onSelect={onSelect}/>
  )
};
