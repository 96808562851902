import request from 'request'
import {Concept, BioportalConcept, Namespace, Rule, NewConceptInfo} from "./qrm";
import v4 from "uuid/v4";
import {distinct} from "./utils";

const PROD_URL = 'https://us-central1-qlaro-rule-manager.cloudfunctions.net/production';
const STAGING_URL = 'https://us-central1-qlaro-rule-manager.cloudfunctions.net/staging';
const DEV_URL = 'http://localhost:8080';

var BASE_URL = DEV_URL;
switch (process.env.REACT_APP_DEPLOYMENT) {
  case 'production':
    BASE_URL = PROD_URL;
    break;
  case 'staging':
    BASE_URL = STAGING_URL;
    break;
  default:
    BASE_URL = DEV_URL;
}

export enum Endpoint {
  NAMESPACE_GET = "/namespace/get",
  NAMESPACE_CREATE = "/namespace/create",
  RULES_GETBYNAMESPACE = "/rules/getByNamespace",
  SAVE_RULE = "/rule/save",
  TAGS_GETALL = "/tags/getAll",
  CONCEPT_GETALL = "/concept/getAll",
  CONCEPT_SAVE = "/concept/save",
  CONCEPT_IMPORT = "/concept/import",
  BIOPORTAL_SEARCH = "/concept/search/bioportal",
  PURL_SEARCH = "/concept/search/purl",
  DEPLOY_RULES="/rule-engine/deploy"
}

const buildURL = (
  endpoint: Endpoint
) => {
  console.debug(`env: ${JSON.stringify(process.env)}`);
  return `${BASE_URL}${endpoint}`;
};

export const getTags = (
  handler: (data: string[]) => void
) => {
  const url = buildURL(Endpoint.TAGS_GETALL);

  request.get({
    url: url,
    json: true,
  }, (err, res, body) => {
    if (err) {
      console.error(`Error during GET on ${url}`);
      console.error(err)
    } else {
      console.debug(`Successful GET from ${url}`);
      console.debug(body);
      handler(body);
    }
  });
};

export const getConcepts = (
  handler: (data: Concept[]) => void
) => {
  const url = buildURL(Endpoint.CONCEPT_GETALL);

  request.get({
    url: url,
    json: true,
    timeout: 500
  }, (err, res, body) => {
    if (err) {
      console.error(`Error during GET on ${url}`);
      console.error(err)
    } else {
      console.debug(`Successful GET from ${url}`);
      console.debug(body);
      handler(body);
    }
  });
};

export const getRulesByNamespace = (
  handler: (data: Rule[]) => void,
  namespaceId: string
) => {
  // get all namespaces
  const url = buildURL(Endpoint.RULES_GETBYNAMESPACE);
  console.debug(`Accessing: ${url}`);
  request.get({
    url: url,
    json: true,
    qs: {id: namespaceId}
  }, (err, res, body) => {
    if (err) {
      console.error(`Error during GET on ${url}`);
      console.error(err)
    } else {
      console.debug(`Successful GET from ${url}`);
      console.debug(body);
      handler(body);
    }
  });

};

export const createNamespace = (
  name: string,
  callback: () => void
) => {
  const url = buildURL(Endpoint.NAMESPACE_CREATE);
  request.post({
    url: url,
    json: true,
    body: {name: name}
  }, (err) => {
    if (err) {
      console.error(`Error during POST on ${url}`);
      console.error(err)
    } else {
      callback();
    }
  })
};

export const getNamespace = (
  handler: (data: Namespace[]) => void,
  id?: string
) => {
  if (id === undefined) {
    // get all namespaces
    const url = buildURL(Endpoint.NAMESPACE_GET);
    console.debug(`Accessing: ${url}`);
    request.get({
      url: url,
      json: true,
    }, (err, res, body) => {
      if (err) {
        console.error(`Error during GET on ${url}`);
        console.error(err)
      } else {
        console.debug(`Successful GET from ${url}`);
        console.debug(body);
        handler(body);
      }
    });
  }
};

export const saveRule = (
  rule: Rule,
  callback?: () => void
) => {
  const url = buildURL(Endpoint.SAVE_RULE);
  request.post({
    url: url,
    json: true,
    body: rule
  }, (err) => {
    if (err) {
      console.error(`Error during POST on ${url}`);
      console.error(err)
    } else {
      callback && callback();
    }
  })
};

export const saveConcept = (
  concept: Concept,
  callback?: () => void
) => {
  const url = buildURL(Endpoint.CONCEPT_SAVE);
  request.post({
    url: url,
    json: true,
    body: concept
  }, (err) => {
    if (err) {
      console.error(`Error during POST on ${url}`);
      console.error(err)
    } else {
      callback && callback();
    }
  })
};

export const importConcepts = (
  conceptInfo: NewConceptInfo,
  concepts: BioportalConcept[],
  callback?: (results:any) => void
) => {
  const url = buildURL(Endpoint.CONCEPT_IMPORT);
  request.post({
    url: url,
    json: true,
    body: {
      conceptInfo: conceptInfo,
      concepts: concepts
    }
  }, (err, httpResponse, body) => {
    if (err) {
      console.error(`Error during POST on ${url}`);
      console.error(err)
    } else {
      console.debug(`Calling callback with body: ${JSON.stringify(body)}`);
      callback && callback(body);
    }
  })
};

const slug = (url: string): string => {
  return url.substr(url.lastIndexOf('/') + 1);
};


export const processSearchResults = (json: any) => {
  return Object.values(json.map((c: any): BioportalConcept => {
    return {
      id: v4(),
      names: [c.prefLabel],
      synonyms: c.synonym || [],
      cuis: c.cui || [],
      definitions: [c.definition],
      purl: c['@id'],
      ontologies: [slug(c.links.ontology)],
      mappingsLinks: [c.links.mappings]
    }
  }).reduce((acc: { [id: string]: BioportalConcept }, cur: BioportalConcept) => {
    let c = acc[cur.purl];

    if (c) {
      c.names = c.names.concat(cur.names).filter(distinct);
      c.synonyms = c.synonyms.concat(cur.synonyms).filter(distinct).sort((a, b) => a.localeCompare(b));
      c.cuis = c.cuis.concat(cur.cuis).filter(distinct);
      c.definitions = c.definitions.concat(cur.cuis).filter(distinct);
      c.ontologies = c.ontologies.concat(cur.ontologies).filter(distinct);
      c.mappingsLinks = c.mappingsLinks && c.mappingsLinks.concat(cur.mappingsLinks || []).filter(distinct);
    } else {
      c = cur;
    }


    acc[cur.purl] = c;

    return acc;
  }, {}))
  //.sort((a: any, b: any) => (a.names[0] as string).localeCompare(b.names[0]));
};

export const bioportalSearchConcept = (
  terms: string,
  ontologies: string[],
  handler: (data: any) => void
) => {
  const url = buildURL(Endpoint.BIOPORTAL_SEARCH);

  request.get({
    url: url,
    json: true,
    qs: {
      terms: terms,
      ontologies: ontologies
    }
  }, (err, res, body) => {
    if (err) {
      console.error(`Error during GET on ${url}`);
      console.error(err)
    } else {
      console.debug(`Successful GET from ${url}`);
      console.debug(body);
      handler(processSearchResults(body.collection));
    }
  });
};

export const searchConceptByPURL = (
  purl: string,
  handler: (data: any) => void
) => {
  const url = buildURL(Endpoint.PURL_SEARCH);

  request.get({
    url: url,
    json: true,
    qs: {
      purl: purl
    }
  }, (err, res, body) => {
    if (err) {
      console.error(`Error during GET on ${url}`);
      console.error(err)
    } else {
      console.debug(`Successful GET from ${url}`);
      console.debug(body);
      handler(body)
      //handler(processSearchResults(body.collection));
    }
  });
};

export const deployRules = (
  activeNamespace: string,
  handler: (data: any) => void
) => {
  const url = buildURL(Endpoint.DEPLOY_RULES);
  console.debug(`network.deployRules() for namespace: ${activeNamespace}`);
  request.post({
    url: url,
    json: true,
    body: {activeNamespace: activeNamespace}
  }, (err, res, body) => {
    if (err) {
      console.error(`Error during POST on ${url}`);
      console.error(err)
    } else {
      console.debug(`Successful POST to ${url}`);
      console.debug(body);
      handler(body);
    }
  });
};
